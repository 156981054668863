import "./Services.css";


export const Services = () => {
    return(
        <>
            <section id="services" className="services section-bg ">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Services</h2>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                            <i className="bi bi-pc-display-horizontal"></i>
                            <h4><a href="/services/custom-software">Custom Software Development</a></h4>
                            <p>Our dedicated team works closely with you to understand your business specific requirements and craft innovative, scalable and high-quality tailored solutions that drive Business efficiency, productivity, and growth</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i className="bi bi-code-square"></i>
                            <h4><a href="/services/efris-integration">EFRIS(URA) Intergration</a></h4>
                            <p>Simplify tax processes and ensure compliance with URA using our EFRIS intergrator.We bridge the gap between your existing Accounting system and EFRIS, automating e-invoicing, e-receipting and data reporting.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i className="bi bi-building-gear"></i>
                            <h4><a href="#777">Website Development</a></h4>
                            <p>Transform your brand with a modern, user-friendly website built to attract customers and drive growth. Our team of experts handles everything from design and development to search engine optimization and ongoing maintenance.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i className="bi bi-phone"></i>
                            <h4><a href="http:/services/mobile-developement">Mobile App Development</a></h4>
                            <p>Boost your business with a custom mobile app. We design and develop user-friendly apps that drive results. From Android and iOS apps to hybrid solutions. We have the expertise to fit your needs and budget.</p>
                            </div>
                        </div>
                    </div>
          
                </div>
            </section>
        </>
    );
}