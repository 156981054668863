import "./OfferSection.css"


var renderList = (pageList = [])=>{
    if(pageList.length > 0){
        return pageList.map((item, index)=>(
            <div className="icon-box" data-aos="fade-up" key={index}>
                <div className="icon"><i className="bi bi-check"></i></div>
                <div className="description">{item}</div>
            </div>
        ));
    }
    else{
        return (
            <></>
        );
    }
}

export const OfferSection = ({title, paragraph, pageList, image})=>{
    const descriptionList = renderList(pageList);
    return (
        <>
            <section id="offer-section" className="offer-section">
            <div className="container">
                    <div className="row">
                        <div 
                            className="col-xl-6 col-lg-6 video-box d-flex justify-content-center align items-stretch order-2 order-md-1 order-lg-1" data-aos="zoom-in"
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize:`cover`,
                                minHeight: `400px`,
                                // objectFit: "cover" 

                            }}
                        >
                            
                            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
                        </div>
                        

                        <div className="col-xl-6 col-lg-6 d-flex flex-column align-items-stretch justify-content-center py-1 px-lg-1 order-1 order-md-2 order-lg-2">
                            <div className="box-heading" data-aos="fade-up">
                                <h4>{title}</h4>
                                {/* <h3>Enim quis est voluptatibus aliquid consequatur fugiat</h3> */}
                                <p>
                                    {paragraph}
                                </p>
                            </div>


                            {descriptionList}

                        </div>



                    </div>
                </div>
            </section>
        </>
    );
}