import "./Team.css";
import team1 from "./team-1.jpg";
import team2 from "./team-2.jpg";
import team3 from "./team-3.png";
// import team4 from "./team-4.jpg";


export const Team = () =>{
    return (
        <>
            <section id="team" className="team">
                <div className="container">
                    <div className="section-title" data-aos="zoom-in">
                        <h2>Team</h2>
                        <h3>Our Hard Working <span>Team</span></h3>
                        <p>United in purpose, strength in diversity, and driven by a shared vision, the Kesilo team stands as a beacon of collaboration, innovation, and excellence.</p>
                    </div>


                    <div className="row">

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up">
                                <div className="member-img">
                                    <img src={team1} className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href="https://twitter.com/KevinBabise"><i className="bi bi-twitter"></i></a>
                                        {/* <a href=".."><i className="bi bi-facebook"></i></a> */}
                                        {/* <a href=".."><i className="bi bi-instagram"></i></a> */}
                                        <a href="https://www.linkedin.com/in/kevin-babise-02442415b/"><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Kevin Micheal Babise</h4>
                                    <span>Chief Executive Officer</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up">
                                <div className="member-img">
                                    <img src={team2} className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href="https://twitter.com/LinusMko"><i className="bi bi-twitter"></i></a>
                                        {/* <a href=".."><i className="bi bi-facebook"></i></a> */}
                                        {/* <a href=".."><i className="bi bi-instagram"></i></a> */}
                                        <a href="https://www.linkedin.com/in/matsiko-linus-179146177/"><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Matsiko Linus</h4>
                                    <span>Project Manager</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up">
                                <div className="member-img">
                                    <img src={team3} className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href="https://twitter.com/enoch_kiirya"><i className="bi bi-twitter"></i></a>
                                        {/* <a href=".."><i className="bi bi-facebook"></i></a> */}
                                        <a href="https://www.instagram.com/prince_enocs"><i className="bi bi-instagram"></i></a>
                                        <a href="https://www.linkedin.com/in/enoch-kiirya"><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Enoch Kiirya</h4>
                                    <span>Financial Manager</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up">
                                <div className="member-img">
                                    <img src={team4} className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href=".."><i className="bi bi-twitter"></i></a>
                                        <a href=".."><i className="bi bi-facebook"></i></a>
                                        <a href=".."><i className="bi bi-instagram"></i></a>
                                        <a href=".."><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Alisemera Medius</h4>
                                    <span>Accounting Officer</span>
                                </div>
                            </div>
                        </div> */}



                    </div>
                </div>
            </section>
        </>
    );
}