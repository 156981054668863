import "./Akauntify.css";

import { Hero } from "../../../components/hero/Hero";
import GlobalExplainer from "../../../components/global-explainer/GlobalExplainer";
// import { BannerSection } from "../components/banner/BannerSection";
import  photo1  from "./akauntify.png"
import photo2 from "./akauntify3.jpg"

export const Akauntify = ()=>{
    const pageSummary = "Akauntify is a financial software solution designed to simplify your accounting processes, enhance compliance with tax regulations, and drive growth for your business. Being already integrated with the EFRIS system, Akauntify enables businesses to track transaction records, issue electronic receipts and invoices, and ensure full compliance with the Uganda Revenue Authority (URA) requirements.";
    const pageIntro = "Take control of your finances, simplify accounting tasks, and ensure compliance with URA EFRIS system–all with one user-friendly platform.";
    const heroTitle = "AKauntify";
    const heroParagraph = "Your Ultimate Accounting Solution";
    const globalData = {
        title:" Key Features of Akauntify ",
        list:[
            {
                title:"Integration with EFRIS System",
                data:"The system is already integrated with EFRIS system ensuring issuance of e-receipts and e-invoices, a requirement for URA"

            },

            {
                title:"User-Friendly Interface",
                data:"Akauntify intuitive interface makes financial management a breeze, whether you're a seasoned accountant or a small business owner."
            },
            {
                title:"Scalable Solution",
                data:"Akauntify grows with your business. Add or remove features as your needs evolve, ensuring that Akauntify remains a valuable asset to your organization."
            },
            {
                title:"Dedicated Support:",
                data:"Our team is committed to supporting you every step of the way. From onboarding to ongoing assistance, we're here to help you succeed with Akauntify."
            },
            {
                title:"Scalability",
                data:"Whether you're a small startup or a large enterprise, our solutions scale with your business, accommodating growth and evolving tax regulations."
            },
            {
                title:"Customizable Reporting",
                data:"Gain valuable insights into your business's financial performance with Akauntify's customizable reporting tools. Tailor reports to your specific needs, track key metrics, and identify trends to make informed business decisions."
            },
          
        ]
    }
    
    return (
        <>
         <Hero pageIntro={pageIntro} pageSummary={pageSummary} heroParagraph={heroParagraph} heroTitle={heroTitle} image={photo2}/>
         <GlobalExplainer globalData={globalData} image = {photo1} />
            {/* <BannerSection colData={bannerData} title={bannerTitle} /> */}
        </>
    );
}

