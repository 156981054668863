import "./Explainer.css";



export const Explainer = ({header, paragraph, image})=>{
    return (
        <>
            <section id="about" className="about">
                <div className="container">
                    <div className="row">
                        <div 
                            className="col-xl-6 col-lg-6 video-box d-flex justify-content-center align-items-stretch order-2 order-md-1 order-lg-1" 
                            data-aos="zoom-in"
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize:`cover`,
                                minHeight: `400px`,
                            }}
                        >
                            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
                        </div>
                        

                        <div className="col-xl-6 col-lg-6 d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5 order-1 order-md-2 order-lg-2">
                            <div className="box-heading" data-aos="fade-up">
                                <h4>{header}</h4>
                                {/* <h3>Enim quis est voluptatibus aliquid consequatur fugiat</h3> */}
                                <p>
                                    {paragraph}
                                </p>
                            </div>

                        </div>



                    </div>
                </div>
            </section>
        </>
    );
}