import React from 'react'
import "./Footer.css";

export const  Footer = () => {
  return (
    <>
      <footer id='footer'>
        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright <strong><span>Kesilo Technologies Ltd</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://kesilo.tech">Kesilo</a>
          </div>
        </div>
      </footer>
    </>
  )
}
