import "./Contact.css";

export const Contact = ()=>{
    return (
        <>

            <section id="contact-us" className="contact-us">
                <div className="container-us">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3989.7383249006!2d32.6413402538208!3d0.362822672918353!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177db934865f4d4b%3A0x4b9299a3a693a704!2sKesilo%20Technologies!5e0!3m2!1sen!2sug!4v1708935803655!5m2!1sen!2sug" frameBorder="0" allowFullScreen title="map" className="map"></iframe>
                </div>
            </section>
            <section id="contact" className="contact">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-4" data-aos="fade-right">
                            <div className="info">
                                <div className="address">
                                    <i className="bi bi-geo-alt"></i>
                                    <h4>Location:</h4>
                                    <p>Kireka-Kamuli Road, Kampala-Uganda</p>
                                </div>

                                <div className="email">
                                    <i className="bi bi-envelope"></i>
                                    <h4>Email:</h4>
                                   <p><a href="mailto:info@kesilo.tech"className="no-underline">info@kesilo.tech</a></p>
                                </div>

                                <div className="phone">
                                    <i className="bi bi-phone"></i>
                                    <h4>Call:</h4>
                                    <p><a href= "tel:+256 764 767838" className="no-underline">+256 764 767838</a></p>
                                    <p>+256 707 021938</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 mt-5 mt-lg-0">
                            <form method="post" className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                </div>
                                <div className="my-3">
                                    {/* <div className="loading">Loading</div> */}
                                    {/* <div className="error-message"></div> */}
                                    {/* <div className="sent-message">Your message has been sent. Thank you!</div> */}
                                </div>
                                <div className="text-center"><button type="submit" className="btn btn-primary" >Send Message</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}