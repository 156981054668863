// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/home/HomePage";
import { AboutPage } from "./pages/about/AboutPage";
import { Layout } from "./pages/layout/Layout";
import { Efris } from "./pages/products/efris/Efris";
import { Contact } from "./pages/contact/ContactPage";
import { CustomSoftware } from "./pages/service-pages/custom-software/CustiomSoftware";
import { Mobile } from "./pages/service-pages/mobile/Mobile";
import { EfrisIntegration } from "./pages/service-pages/efris-integration/efrisIntegration";
import { Akauntify } from "./pages/products/akauntify/Akauntify";
import { Quickbooks } from "./pages/products/QB-Integrator/Quickbooks";


export const RouterComponent = () =>{
    return (
        <Router basename="/">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path="about-us" element={<AboutPage />}  />
                    
                    <Route path="services/custom-software" element={<CustomSoftware />} />
                    
                    <Route path="services/mobile-developement" element={<Mobile />} />
                    <Route path="services/efris-integration" element={<EfrisIntegration />} />
                    <Route path="products/efris-api" element={<Efris />} />
                    <Route path="products/akauntify" element={<Akauntify  />} />
                    <Route path="products/quickbooks-efris-integrator" element={<Quickbooks  />} />

                
                    
                    <Route path="contact-us" element={<Contact />} />
                </Route>
            </Routes>
        </Router>
    );
};

// export default RouterComponent;