import "./GlobalExplainer";
import React from 'react';


var renderSectionData = (data = [])=>{
    if(data.length > 0){
        return data.map((item, index) => {
            return (

                <li key={index}>
                    <strong>{item.title} :</strong> {item.data}
                </li>
            );
        })
    }
    else{
        return (
            <></>
        );
    }
}

export default function GlobalExplainer({globalData = {list:[]}, image}) {

    const sectionRender = renderSectionData(globalData.list)
  return (
    <>
        <section id="about" className="about col-banner">
                <div className="container">
                    <div className="row">


                        <div 
                            className="col-xl-6 col-lg-6 video-box d-flex justify-content-center align-items-stretch" 
                            data-aos="zoom-in"
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize:`cover`,
                                minHeight: `400px`,
                            }}
                        >
                            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
                        </div>


                        <div className="col-xl-6 col-lg-6 d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <div className="box-heading global mb-0" data-aos="fade-up">
                                <h4>{globalData.title}</h4>
                                <p>{globalData.paragraph}</p>
                            </div>


                            <ul className="col-list">
                                {sectionRender}
                            </ul>



                           


                        </div>

                        
                    </div>
                </div>
            </section>
    </>
  )
}
