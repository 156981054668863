import { Hero } from "../../../components/hero/Hero";
import { Explainer } from "../components/explainer/Explainer";
import { ColBanner } from "../components/col-banner/ColBanner";
import name from "./custom.png";
import photo from "./expert.JPG";
import photo1 from "./cutomized.jpg"

export const CustomSoftware = ()=>{

    const pageSummary = "In today's dynamic business landscape, off-the-shelf software solutions may not always meet the specific requirements of your business. we understand that a one-size-fits-all approach doesn't cut it and that's why we choose to offer customized software development – crafting software solutions that perfectly fit your organization's workflows, processes, and goals.";
    const pageIntro = "Custom software development is a way to deliver tailored functionality for specific users or business functions.Work with our team of developers to quickly design, develop, integrate, test, and deploy high-quality tailored software.";
    const heroTitle = "CUSTOM SOFTWARE DEVELOPMENT";
    const heroParagraph = "We crafting Your Vision into Reality: Tailored Software Solutions.";
    const header = "Our custom software service";
    const paragraph = "Our dedicated team works around the clock to build custom software solutions tailored to your specific industry and business needs. Our custom software development process emphasizes a user-centric approach, ensuring creation of highly efficient solutions. We partner with clients openly and transparently to deliver innovative and impactful software.Let's collaborate to explore how we can enhance your competitive edge with tailored software solutions. The potential is limitless when you're partnered with the right development team.";

    const ColBannerData = [
        {
            title:"Discovery and Analysis",
            paragraph:"Through clear consultations with the client, we dig deep into their business goals, challenges, and target audience. We then gather information about existing systems and desired functionalities. This comprehensive analysis allows us to define clear project goals, scope, and specifications."
        },
        {
            title:"Design and Development",
            paragraph:"Our team designs the software architecture, user interface, and technical specifications, utilizing appropriate technologies, languages, and frameworks. we build, test, and refine the software solution, incorporating client feedback at each stage."
        },
        {
            title:"Testing and Deployment",
            paragraph:" The built software undergoes testing to identify and fix any bugs, errors, or performance issues. To further guarantee the software meets the client's needs, user acceptance testing (UAT) is conducted. Then the software is deployed in the client's environment."
        },
    ];

    const whyUs = {
        title:"Why Custom Software?",
        list:[
            {
                title:"Tailored Solutions",
                data:"It's designed to meet the unique needs of the business, unlike off-the-shelf software."
            },
            {
                title:"Flexibility and Scalability",
                data:"It can easily adapt to changes in the business environment, allowing for growth and expansion without limitations."
            },
            {
                title:"Improved User Experience",
                data:"Custom software can be designed with a focus on user needs, leading to higher satisfaction and productivity."
            },
            {
                title:"Competitive Advantage",
                data:"Custom software sets businesses apart from competitors by offering unique features and services, driving innovation and success in the market."
            },
        ]
    };
    return (
        <>
            <Hero pageSummary={pageSummary} pageIntro={pageIntro} heroTitle={heroTitle} heroParagraph={heroParagraph} image={name} />   
            <Explainer header={header} paragraph={paragraph} image={photo}  />
            <ColBanner banner={ColBannerData} sectionData={whyUs} image={photo1}  /> 
        </>
    );
}