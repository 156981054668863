import React from 'react';
import { Hero } from "./../../components/hero/Hero";
import { Services } from '../../components/services/Services';
import { Technologies } from '../../components/technologies/Technologies';
import name from "./homepage2.jpg";

export const HomePage =() => {
  const pageSummary = "Every business is now a digital business. The challenge is applying technology to its fullest potential in any organization. We provide you with quality services in each phase of creating software solutions, from strategizing and development, through integration and implementation, to maintenance and support.";

  const pageIntro = "Our team is dedicated to creating digital solutions that perfectly align with your business goals and objectives. Whether you're looking to streamline internal processes, enhance customer experiences, or innovate within your industry, we're here to help.";
  const heroTitle = "YOUR PARTNER FOR CUSTOM DEVELOPED DIGITAL SOLUTIONS";
  

  const heroParagraph = "\"Simplifying Business, Amplifying Success\"";
  return (
    <>
      <Hero pageSummary={pageSummary} pageIntro={pageIntro} heroTitle={heroTitle} heroParagraph={heroParagraph} image={name} />
      <Services />
      <Technologies />
    </>
  )
}
