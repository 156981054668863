import { Explainer } from "../components/explainer/Explainer";
import { ColBanner } from "../components/col-banner/ColBanner";
import { Hero } from "../../../components/hero/Hero";
import bg_image from "./flutter.jpeg";
import bc_image from "./image1.png"
import bb_image from "./mobile.jpg"

export const Mobile = ()=>{

    const pageSummary = "Mobile apps have become essential tools for enhancing productivity, staying connected, and accessing information on the go. we understand the significance of mobile technology and its transformative impact on businesses and individuals alike. With our expertise in mobile app development, we strive to create intuitive, user-friendly applications that empower our clients to reach their goals and engage with their audience effectively.";
    const pageIntro = "Maximize your business's mobile presence with our cutting-edge mobile app development service. Elevate engagement, foster customer loyalty, and stay ahead of the curve in today's mobile-first world. Let's create your mobile success story together.";
    const heroTitle = "MOBILE APP DEVELOPMENT";
    const heroParagraph = "Transforming Ideas into Interactive Apps.";
    const header = "OUR HIGH-END MOBILE APP DEVELOPMENT SERVICE";
    const paragraph = "We develop cutting-edge mobile applications with excellent UX (User Experience) across multiple platforms including Android and IOS. Whether it is a consumer-oriented app or a transformative enterprise-class solution, our team leads the entire mobile app development process from ideation and concept to delivery and to ongoing support.";


    const ColBannerData = [
        {
            title:"iOS",
            paragraph:" We develope mobile applications for devices using iOS operating system such as iphones,apple smart TVs, ipads.Our team employs the latest iOS technology in developing a flexible and customized mobile app concerning ease of transaction through the app while extending the reach of existing web portals."
        },
        {
            title:"Android",
            paragraph:"We develope mobile applications for devises using Android Operating system such android smartphones,smart TVs and Tablets. Our mobile app developers employ the latest Android technology in developing the customized mobile app in relavance to client requirements."
        },
        {
            title:"Cross-platform",
            paragraph:"We develope mobile applications that can be deployed on both iOS and Android as well as desktop and web applications. Get an excellent B2B, B2E or B2C enterprise mobility solution securely by interfacing with any type of corporate systems with extension to the relavant web interface."
        },
    ];

    const whyUs = {
        title:"OUR MOBILE APP DEVELOPMENT PROCESS",
        paragraph:"At KESILO, we understand that every element of the development process is critical, thus we've established the competence to offer a comprehensive suite of mobile application development services. We can handle design, coding, management, and integration, or we may start from scratch and build your product into a fully functional application. Whether you're a start-up or a large corporation, we'll adapt to your needs and develop a stunning digital product that fulfils your expectations.Below are the stages we follow to develope a mobile application",
        list:[
            {
                title:"Ideation ",
                data:"We conduct thorough Product Discovery and Research."
            },
            {
                title:"Design",
                data:"We align exceptional UX, compelling UI, and powerful branding, elevating your mobile app beyond mere functionality."
            },
            {
                title:"Development",
                data:"Coding the app by our experience team."
            },
            {
                title:"Quality Assurance",
                data:"Testing the app and making sure everthing is working well"
            },
            {
                title:"Mantanence and Support",
                data:"Co-managing the app with your technology team"  
            },
        ]
    };
    return (
        <>
            <Hero pageSummary={pageSummary} pageIntro={pageIntro} heroTitle={heroTitle} heroParagraph={heroParagraph} image={bg_image} />   
            <Explainer header={header} paragraph={paragraph} image ={bc_image}/>
            <ColBanner banner={ColBannerData} sectionData={whyUs} image={bb_image} />
        </>
    );
}