import React from 'react';
import { Hero } from '../../components/hero/Hero';
import { AboutUs } from '../../components/about-us/AboutUs';
import { Mission } from '../../components/mission/Mission';
import { Team } from '../../components/team/Team';
import name from './abc.jpg'

export const AboutPage =()=> {
  const pageSummary = " Kesilo Technologies Limited is a software design and development company currently offering EFRIS integration, web solutions and customized software development. We are a progressive team made up of creative, passionate, and dedicated tech experts with an aim of empowering businesses in pursuit of digital transformation. Partner with us, and let's build the future together.";
  const pageIntro = "We are not just software developers but architects of innovation, dedicated to crafting creative solutions that empower businesses. With personalized service and a commitment to excellence, we're shaping a brighter digital future together. Experience the difference as we redefine what's possible in technology.";
  const heroTitle = "WHO WE ARE";
  const heroParagraph = "Kesilo Technologies Limited is a software development firm situated in Kireka-Kamuli, Uganda. We collaborate with businesses seeking cutting-edge solutions to gain a competitive edge in their respective markets.";
  return (
    <>
      <Hero pageSummary={pageSummary} pageIntro={pageIntro} heroTitle={heroTitle} heroParagraph={heroParagraph} image={name} />
      <AboutUs />
      <Mission />
      <Team />
    </>
  )
}
