import "./Technologies.css";
import client_1 from "./client-1.png";
import client_2 from "./client-2.png";
import client_3 from "./client-3.png";
import client_4 from "./client-4.png";
import client_5 from "./client-5.png";
import client_6 from "./client-6.png";
import client_7 from "./client-7.png";
import client_8 from "./client-8.png";
import client_9 from "./client-9.png";
import client_10 from "./client-10.png";
import client_11 from "./client-11.png";
import client_12 from "./client-12.png";
import client_13 from "./client-13.png";
import client_14 from "./client-14.png";
import client_15 from "./client-15.png";
import client_16 from "./client-16.png";
// import client_17 from "./client-17.png";
import client_18 from "./client-18.png";
import client_19 from "./client-19.png";
import client_20 from "./client-20.png";
import client_21 from "./client-21.png";
import client_22 from "./client-22.png";
import client_23 from "./client-23.png";
import client_24 from "./client-24.png";
import client_25 from "./client-25.png";
import client_26 from "./client-26.png";
import client_27 from "./client-27.png";
import client_28 from "./client-28.png";
import client_29 from "./client-29.png";
import client_30 from "./client-30.png";
import client_31 from "./client-31.png";















export const Technologies = () =>{
    return(
        <>
            <section id="techies" className="techies">
                <div className="container" data-aos="zoom-in">
                    <div className="section-title">
                        <h2>Some of the Technologies we use</h2>
                        {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.</p> */}
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_1} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_2} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_3} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_4} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_5} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_6} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_7} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_8} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_9} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_10} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_11} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_12} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_13} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_14} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_15} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_16} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_18} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_19} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_20} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_21} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_22} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_23} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_24} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_25} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_26} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_27} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_28} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_29} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_30} className="img-fluid" alt="" /></div>
                        <div className="col-3 col-sm-2 col-lg-1 mb-4"><img src={client_31} className="img-fluid" alt="" /></div>
                    
                    </div>

                </div>
            </section>
        </>
    )
}