import "./Hero.css";
import { AfterHero } from "../afterhero/AfterHero";


export const Hero = ({pageSummary,pageIntro,heroTitle,heroParagraph, image}) =>{
    
    return (
        <>
            <section 
                id="hero" 
                className="d-flex align-items-center"
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-xl-7">
                            <h1>{heroTitle}</h1>
                            <h2>{heroParagraph}</h2>
                            {/* <a href="#about" class="btn-get-started scrollto">Get Started</a> */}
                        </div>
                    </div>
                    
                </div>
            </section>
            <AfterHero pageSummary={pageSummary} pageIntro={pageIntro} />
        </>
    );
}