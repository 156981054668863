// import logo from './logo.svg';
import './App.css';
import { RouterComponent } from './RouterComponent';

function App() {
  return (
    <RouterComponent />
  );
}

export default App;
