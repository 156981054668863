import "./Mission.css";


export const Mission = ()=>{
    return(
        <>
            <section id="mission" className="mission">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>OUR ROADMAP</h2>
                    </div>


                    <div className="row">
                        <div className="col-md-4 col-lg-4">
                            <h4>VISION</h4>
                            <p>
                            Empowering business success through innovative software solutions.We pledge to illuminate the path to success for businesses, igniting possibilities through our unwavering commitment to cutting-edge creativity and technological excellence.
                            </p>
                        </div>

                        <div className="col-md-4 col-lg-4">
                            <h4>MISSION</h4>
                            <p>
                            To enable business excellence through cutting-edge software solutions, streamlining operations from finance to stock management, ensuring tax compliance, and empowering growth.
                            </p>
                        </div>

                        <div className="col-md-4 col-lg-4">
                            <h4>CORE VALUES</h4>
                                <ul className="cvalues">
                                    <li><i className="bi bi-lightbulb-fill icon"></i><span className="item">Customer Focus</span></li>
                                    <li><i className="bi bi-lightbulb-fill icon"></i><span className="item">Intergrity</span></li>
                                    <li><i className="bi bi-lightbulb-fill icon"></i><span className="item">Innovation</span></li>
                                    <li><i className="bi bi-lightbulb-fill icon"></i><span className="item">Collaboration</span></li>

                                    
                                </ul>
                        </div>
                    </div>
          
                </div>
            </section>
            <section id="mission-bottom" className="mission-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <div className="box-heading" data-aos="fade-up">
                                <h4>Why Us</h4>
                            </div>


                            <ul className="icon-box-list">
                                <li>
                                    <p className="description">Our team possesses extensive experience and expertise in software development and digital transformation.</p>
                                </li>
                                <li>
                                    <p className="description">Excellence is at the heart of everything we do. From code quality to user experience, we are committed to delivering the highest standards of quality in every project. </p>
                                </li>
                                <li>
                                    <p className="description">We prioritize understanding your unique needs and developing solutions that exceed your expectations.</p>
                                </li>
                                <li>
                                    <p className="description">We thrive on creativity and innovation, constantly pushing the boundaries to exceed client expectations.</p>
                                </li>
                                <li>
                                    <p className="description">Our relationship with our clients doesn't end with project delivery. We provide ongoing support and maintenance to ensure that your software continues to perform at its best.</p>
                                </li>
                            </ul>


                            

                        </div>

                        <div className="col-xl-6 col-lg-6 video-box-mission d-flex justify-content-center align-items-stretch order-2 order-md-1 order-lg-1" data-aos="zoom-in">
                            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}