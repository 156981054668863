import "./AfterHero.css"


export const AfterHero = ({pageSummary,pageIntro}) =>{
    return (
        <>
            <div id="after-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 order-2 order-lg-1 mt-3 mt-lg-5">
                            <p className="page-summary">
                                {pageSummary}
                            </p>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-1 offset-lg-1">
                            <div className="page-intro page-intro-size-default">
                                {pageIntro}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}