import "./AboutUs.css";



export const AboutUs = ()=>{
    return (
        <>
            <section id="about" className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 video-box-2 d-flex justify-content-center align-items-stretch order-2 order-md-1 order-lg-1" data-aos="zoom-in">
                            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
                        </div>
                        

                        <div className="col-xl-6 col-lg-6 d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5 order-1 order-md-2 order-lg-2">
                            <div className="box-heading" data-aos="fade-up">
                                <h4>Our Expertise</h4>
                                {/* <h3>Enim quis est voluptatibus aliquid consequatur fugiat</h3> */}
                                <p>
                                We are a team of driven individuals who understand the significance of continuous self-improvement. Our focus is on constantly expanding our portfolio with the latest technologies. Currently, our experience includes;
                                </p>
                            </div>

                            



                            <div className="icon-box" data-aos="fade-up">
                                <div className="icon"><i className="bi bi-check"></i></div>
                                <div className="description">Custom software Development</div>
                            </div>

                            <div className="icon-box" data-aos="fade-up">
                                <div className="icon"><i className="bi bi-check"></i></div>
                                <div className="description">EFRIS Intergration</div>
                            </div>

                            <div className="icon-box" data-aos="fade-up">
                                <div className="icon"><i className="bi bi-check"></i></div>
                                <div className="description">User Experience(UX) Design</div>
                            </div>

                            <div className="icon-box" data-aos="fade-up">
                                <div className="icon"><i className="bi bi-check"></i></div>
                                <div className="description">Customer Support and Mantainence</div>
                            </div>

                        </div>



                    </div>
                </div>
            </section>
        </>
    );
}