import "./Quickbooks.css";

import { Hero } from "../../../components/hero/Hero";
import GlobalExplainer from "../../../components/global-explainer/GlobalExplainer";
import photo1 from "./Quickbooksp.png";
import photo2 from "./qbimage.png";

export const Quickbooks = ()=>{
    const pageSummary = "Our secure integration eliminates the need for manual data entry between your QuickBooks package and the EFRIS system. This automation minimizes errors, saves valuable time and resources, and provides real-time updates on the status of your EFRIS submissions. With our Quickbooks integration, you can focus on growing your business while ensuring effortless compliance with Ugandan tax regulations.";
    const pageIntro = "You don't have to hustle with filing EFRIS returns while using QuickBooks. KESILO has got you covered.";
    const heroTitle = "QUICKBOOKS EFRIS INTEGRATOR";
    const heroParagraph = " Generate invoices and receipts in QuickBooks and automatically transmit them to EFRIS for approval.";
    const globalData = {
        title:" Key Features of Quickbooks Integrator ",
        list:[
            {
                title:"Create Invoices/Receipts in QuickBooks",
                data:"As usual, generate invoices or receipts within your familiar QuickBooks environment, irrespective of the online or desktop version."
            },
            {
                title:"Automatic Data Synchronization",
                data:"Our integration service automatically captures relevant invoice/receipt data directly from your chosen QuickBooks platform."
            },
            {
                title:"EFRIS Processing and Approval",
                data:"The captured data is formatted and transmitted to the EFRIS system for URA approval."
            },
            {
                title:"Real-Time Updates:",
                data:"Receive instant notifications and track the status of your submissions directly from the integration platform."
            },  
        ]
    }

    
    return (
        <>
         <Hero pageIntro={pageIntro} pageSummary={pageSummary} heroParagraph={heroParagraph} heroTitle={heroTitle} image = {photo2}/>
         <GlobalExplainer globalData={globalData}  image = {photo1}/>
            {/* <BannerSection colData={bannerData} title={bannerTitle} /> */}
        </>
    );
}

