import React from 'react';
import "./TopBar.css";

export const TopBar = ()=> {
  return (

        <section id="topbar" className="d-flex align-items-center">
            <div className="container d-flex justify-content-center justify-content-md-between">
                <div className="contact-info d-flex align-items-center">
                    <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:info@kesilo.tech" className="no-underline">info@kesilo.tech</a></i>
                    <i className="bi bi-phone d-flex align-items-center ms-4"><span><a href= "tel:+256 764 767838" className="no-underline">+256 764 767838</a></span></i>
                </div>
                <div className="social-links d-none d-md-flex align-items-center">
                    <a href="https://twitter.com/Kesilo_tech" className="twitter"><i className="bi bi-twitter"></i></a>
                    <a href="https://www.facebook.com/kesilo.tech" className="facebook"><i className="bi bi-facebook"></i></a>
                    <a href="https://www.instagram.com/kesilo_technologies/" className="instagram"><i className="bi bi-instagram"></i></a>
                    <a href="https://www.linkedin.com/company/kesilo-technologies-limited" className="linkedin"><i className="bi bi-linkedin"></i></a>
                </div>
            </div>
        </section>
  )
}
