import { Outlet} from "react-router-dom";
import { NavBar } from "../../components/navBar/Navbar"
import { Footer } from "../../components/footer/Footer";



export const Layout = () => {
    return (
        <>
        <NavBar />
        <Outlet />
        <Footer />
        </>
    );
}