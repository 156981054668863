import "./Efris.css";
import { Hero } from "../../../components/hero/Hero";
import { OfferSection } from "../components/offer-section/OfferSection";
// import { BannerSection } from "../components/banner/BannerSection";
import efris_image from "./Users.png";
import background from "./apiimage.png";

export const Efris = ()=>{
    const pageSummary = "We offer a comprehensive EFRIS API integration service designed to simplify your tax compliance journey. Our secure and efficient solution connects your existing accounting software with the URA's EFRIS system, automating data submission and streamlining your business flow.";
    const pageIntro = "Managing EFRIS system compliance in Uganda can be a complex task. Manually entering invoice data is time-consuming and prone to errors.This is where we come in!";
    const heroTitle = "EFRIS API";
    const heroParagraph = "Streamline Ugandan Tax Compliance & Boost Efficiency with Our EFRIS API Expertise";
    const offerCheckedList = [
        "Expert Integration: Our team seamlessly integrates our secure API with your accounting software..",
        "Automated Data Extraction: Relevant invoice data is automatically extracted and formatted according to EFRIS specifications.",
        "Secure API Connection: Data is transmitted securely through our encrypted API to the URA's EFRIS system.",
        "Real-Time Validation and Processing: The EFRIS system validates your data and generates electronic receipts or invoices..",
        "Data Backup and Access: We provide secure data storage and easy access to your e-receipts for record-keeping purposes.",
    ];
    const offerParagraph = ""
    const offerTitle = "How it works?";

    return (
        <>
            <Hero pageIntro={pageIntro} pageSummary={pageSummary} heroParagraph={heroParagraph} heroTitle={heroTitle} image = {background} />
            <OfferSection image={efris_image} title={offerTitle} pageList={offerCheckedList} paragraph={offerParagraph} />
            {/* <BannerSection colData={bannerData} title={bannerTitle} /> */}
        </>
    );
}