import { Hero } from "../../../components/hero/Hero";
// import { Explainer } from "../components/explainer/Explainer";
import { ColBanner } from "../components/col-banner/ColBanner";
import name from "./image5.jpeg";
// import name6 from "./inteimage.jpg"
import GlobalExplainer from "../../../components/global-explainer/GlobalExplainer";
import photo from "./efris.jpg"
import photo1 from "./Intergrator.png"

export const EfrisIntegration = () => {
    const pageSummary = "The Uganda Revenue Authority (URA) has implemented the Electronic Fiscal Receipting and Invoicing System (EFRIS) to improve taxpayer compliance and streamline VAT reporting. All VAT-registered businesses must now use EFRIS system for every business transaction. EFRIS refers to the use of Electronic Fiscal Devices (EFDs), e-Invoicing, or direct communication with business transaction systems to handle the issuance of e-invoices in accordance with the Tax Procedures Code Act 2014.";
    const pageIntro = "Are you a Ugandan business owner struggling to navigate the complexities of EFRIS? Look no further! KESILO offers seamless EFRIS integration solutions to simplify your tax compliance and streamline your business processes.";
    const heroTitle = "EFRIS INTEGRATION";
    const heroParagraph = "Integrate your accounting system with URA EFRIS.";
    // const header = "Tailored solutions are the key to addressing specific needs and achieving optimal outcomes";
   
    // const paragraph = "ppppp";

    const ColBannerData = [
        {
            title:"E-invoicing",
            paragraph:"The e-invoicing component supports issuance and storing invoice information in an electronic format through a system to system connections.We integrate your invoicing system with EFRIS to generate e-receipts and invoices via a web service API connection. Issuance of e-receipts and invoices can also be done in offline mode."
        },
        {
            title:"Requirements",
            paragraph:"On addition to integrating your existing accounting system, you will need an active  e-tax portal account, internet connectivity, Virtual Private Network connectivity (VPN), offline mode support and E-receipt/invoice validation."
        },
        {
            title:"Testing and Deployment",
            paragraph:" The built software undergoes testing to identify and fix any bugs, errors, or performance issues. To further guarantee the software meets the client's needs, user acceptance testing (UAT) is conducted. Then the software is deployed in the client's environment."
        },
    ];

    const whyUs = {
        title:"Our EFRIS integration process",
        list:[
            {
                title:"Consultation and collaboration",
                data:"We start by understanding your business requirements and current systems to determine the best approach for EFRIS integration."
            },
            {
                title:"Development",
                data:"Our software developers will configure or develop custom integration modules to connect your accounting software with EFRIS."
            },
            {
                title:"Testing",
                data:"Testing is conducted to ensure that the integration functions flawlessly and meets all compliance standards."
            },
            {
                title:"Deployment ",
                data:"Once testing is complete, we deploy the EFRIS integration solution in your environment, providing necessary training and support along the way."
           
            },

            {
                title:"Maintenance ",
                data:"Our support team is always available to address any issues or updates needed post-deployment, ensuring smooth operation of your integrated system."
           
            },


        ]
    };

    const globalData = {
        title:" Key Features of Our EFRIS Integration Services",
        // paragraph:"pppppppppppppppppppppppppp",
        list:[
            {
                title:"Tailored Solutions",
                data:"We integrate EFRIS with your existing accounting system(QuickBooks, Zoho, Odoo etc ) to fit your unique requirements."
            },
            {
                title:"Efficiency",
                data:"Say goodbye to manual data entry and human errors. Our integration automates the generation and submission of fiscal documents, saving you time and resources."
            },
            {
                title:"Compliance Assurance",
                data:"Stay ahead of regulatory changes and avoid penalties with our EFRIS integration, which ensures full compliance with tax authorities' requirements."
            },
            {
                title:"Scalability",
                data:"Whether you're a small startup or a large enterprise, our solutions scale with your business, accommodating growth and evolving tax regulations."
            },
            {
                title:"User-Friendly Interface",
                data:"Our intuitive interface makes navigating through EFRIS features a breeze, empowering your team to efficiently manage tax-related tasks."
            },
          
        ]
    }
    return (
        <>
            <Hero pageSummary={pageSummary} pageIntro={pageIntro} heroTitle={heroTitle} heroParagraph={heroParagraph} image={name}/>   
            <GlobalExplainer globalData={globalData} image = {photo1}/>
            {/* <Explainer header={header} paragraph={paragraph}  /> */}
            <ColBanner banner={ColBannerData} sectionData={whyUs} image = {photo} /> */
        </>
    );
}





