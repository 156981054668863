import { TopBar } from "./../topbar/TopBar";
import "./Navbar.css";
import kesilo from "./kesilo.png"; 
import React, { useState, useEffect} from "react";

export const NavBar = () =>{
    const [activeLink, setActiveLink] = useState("/"); // State to store the active link
    const [isMobile, setIsMobile] = useState(false);


    useEffect(()=>{
        // const currentPath = window.location.pathname;

        const handleHashChange = ()=>{
            const newHash = window.location.hash;
            // alert(newHash);
            setActiveLink(newHash);
            setIsMobile(false);
        }

        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
        
        // alert(window.location.href)
        // setActiveLink(currentPath);
    },[]);
    
    

     const handleDropdownClick = (e) => {
        e.preventDefault();
        if (document.getElementById("navbar").classList.contains("navbar-mobile")) {
            e.preventDefault();
            e.currentTarget.nextElementSibling.classList.toggle("dropdown-active");
        }
    };

    return (
        <>
            <TopBar />
            <header id="header" className="d-flex align-items-center">
                <div className="container d-flex justify-content-between">

                    <div className="logo">
                        {/* <h1 className="text-light"><a href="/">KESILO</a></h1> */}
                        <h1 className="text-light"><a href="/"><img src={kesilo} alt=""/></a></h1>
                        {/* { <a href="/"><img src="assets/img/Kesilo" alt="" className="img-fluid" /></a> } */}
                    </div>

                    <nav id="navbar" className={`${ isMobile ? "navbar-mobile" : "navbar"}`}>
                        <ul>
                            <li>
                                <a className={`nav-link scrollto ${ (activeLink === "/" || activeLink === "#/") ? "active" : ""}`} href="#/">Home</a>
                            </li>
                            
                            <li>
                                <a className={`nav-link scrollto ${ (activeLink === "#/about-us") ? "active" : ""}`} href="#/about-us">About Us</a>
                            </li>

                            <li className="dropdown">
                                <a href="#999" className={`nav-link scrollto ${ activeLink.includes("/services") ? "active" : ""}`} onClick={handleDropdownClick}>
                                    Services <i className="bi bi-chevron-down"></i>
                                </a>

                                <ul>
                                    <li>
                                        <a href="/#/services/custom-software">Custom Software</a>
                                    </li>

                                    <li>
                                        <a href="/#/services/mobile-developement">Mobile Development</a>
                                    </li>

                                    <li>
                                        <a href="/#/services/efris-integration">Efris Integration</a>
                                    </li>
                                </ul>
                            </li>

                            <li className="dropdown">
                                <a href="#6666" className={`nav-link scrollto ${ activeLink.includes("/products") ? "active" : ""}`} onClick={handleDropdownClick} > 
                                    Products <i className="bi bi-chevron-down"></i>
                                </a>

                                <ul>
                                    <li><a href="/#/products/efris-api">Efris Api</a></li>
                                    <li><a href="/#/products/akauntify">Akauntify</a></li>
                                    <li><a href="/#/products/quickbooks-efris-integrator">Quickbooks Efris Integrator</a></li>
                                </ul>
                            </li>
                            
                            <li>
                                <a className={`nav-link scrollto ${ activeLink === "/contact-us" ? "active" : ""}`} href="/#/contact-us">CONTACT</a>
                            </li>

                        </ul>
                        <i className={`bi mobile-nav-toggle ${ isMobile ? "bi-x":"bi-list"}`} id="mobile-nav-toggle" onClick={() => setIsMobile(!isMobile)}></i>
                    </nav>


                </div>
            </header>
        </>
    );
} 