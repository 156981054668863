// import { configure } from "@testing-library/react";
import "./ColBanner.css";
// import { contains } from "jquery";

var renderData = (data = [])=>{
    if(data.length > 0){
        return data.map((item, index) => {
            return (
                <div key={index} className="col-md-4 col-lg-4">
                    <h4>{item.title}</h4>
                    <p>{item.paragraph}</p>
                </div>
            );
        });
    }
    else{
        return (<></>);
    }
}

var renderSectionData = (data = [])=>{
    if(data.length > 0){
        return data.map((item, index) => {
            return (
                <li key={index}>
                    <strong>{item.title} :</strong> {item.data}
                </li>
            );
        })
    }
    else{
        return (
            <></>
        );
    }
}

export const ColBanner = ({banner, sectionData = {list:[]}, image})=>{
    const bannerRender = renderData(banner);
    // alert(JSON.stringify(sectionData.list));
    const sectionRender = renderSectionData(sectionData.list)
    return(
        <>
            <section id="mission" className="mission">
                <div className="container" data-aos="fade-up">
                    {/* <div className="section-title">
                        <h2>Our Mission</h2>
                    </div> */}


                    <div className="row">
                        {bannerRender}
                        
                    </div>
          
                </div>
            </section>
            <section id="about" className="about col-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <div className="box-heading" data-aos="fade-up">
                                <h4>{sectionData.title}</h4>
                                <p>{sectionData.paragraph}</p>
                            </div>


                            <ul className="col-list">
                                {sectionRender}
                            </ul>


                           


                        </div>

                        <div 
                            className="col-xl-6 col-lg-6 video-box d-flex justify-content-center align-items-stretch order-2 order-md-1 order-lg-1" 
                            data-aos="zoom-in"
                            style={{
                                
                                backgroundImage: `url(${image})`,
                                // backgroundSize:`cover`,
                                minHeight: `350x`,
                                maxHeight: `550px`,
                                // backgroundSize: `contain`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: `center center`,
                                backgroundSize: `cover`
                            }}
                        >
                            {/* <img src={image} alt="" /> */}
                            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}